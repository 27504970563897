<template>
  <footer class="footer" :class="{ knives: $route.name === 'knives' }">
    <div class="container">
      <a v-if="$route.name === 'knives'" class="footer__logo" target="_blank">
        <img
          v-if="$i18n.locale === 'ru'"
          src="@/assets/img/icons/logo-footer-knives.svg"
          alt="Logo Magnum"
          width="223"
          height="66"
        />
        <img
          v-else
          src="@/assets/img/icons/logo-footer-knives-kz.svg"
          alt="Logo Magnum"
          width="723"
          height="66"
        />
      </a>
      <a v-else class="footer__logo" target="_blank">
        <img
          v-if="$i18n.locale === 'ru'"
          src="@/assets/img/icons/logo-footer.svg"
          alt="Logo Magnum"
          width="223"
          height="66"
        />
        <img
          v-else
          src="@/assets/img/icons/logo-footer-kz.svg"
          alt="Logo Magnum"
          width="723"
          height="66"
        />
      </a>
      <div class="footer__menu">
        <div class="footer__left">
          <div
            v-for="(accordion, index) in accordions"
            :key="index"
            class="footer-item"
          >
            <div
              class="footer-item__title"
              @click="toogleAccordions(accordion)"
              :class="{ 'is-active': accordion.isOpen }"
            >
              {{ $t(accordion.title) }}
            </div>
            <div
              v-for="(item, index) in accordion.list"
              :key="index"
              class="footer-item__list"
              :class="{ 'is-active': accordion.isOpen }"
            >
              <locale-router-link
                v-if="item.router"
                :to="item.router"
                class="footer-item__link"
              >
                {{ $t(item.label) }}
              </locale-router-link>
              <a v-else :href="item.link" class="footer-item__link">
                {{ $t(item.label) }}
              </a>
            </div>
          </div>
        </div>

        <div class="footer-info">
          <div class="footer-info__support">{{ $t("support") }}</div>

          <div class="footer-info__phone">
            <a target="_blank" href="tel:7766">7766</a
            ><span>{{ $t("freeCall") }}</span>
          </div>

          <div v-if="social" class="footer-info__social-links">
            <span>{{ $t("weAreInSocialNetworks") }}</span>

            <div class="footer-info__links">
              <a
                v-if="social.attributes.facebook_active"
                class="fb"
                :href="social.attributes.facebook_link"
                target="_blank"
              ></a
              ><a
                v-if="social.attributes.IG_active"
                class="insta"
                :href="social.attributes.IG"
                target="_blank"
              ></a
              ><a
                v-if="social.attributes.twitter_active"
                class="twitter"
                :href="social.attributes.twitter"
                target="_blank"
              ></a
              ><a
                v-if="social.attributes.tiktok_active"
                class="tiktok"
                :href="social.attributes.tiktok"
                target="_blank"
              ></a
              ><a
                v-if="social.attributes.telegram_active"
                class="telegram"
                :href="social.attributes.telegram"
                target="_blank"
              ></a
              ><a
                v-if="social.attributes.vk_active"
                class="vk"
                :href="social.attributes.vk"
                target="_blank"
              ></a
              ><a
                v-if="social.attributes.ok_active"
                class="ok"
                :href="social.attributes.ok"
                target="_blank"
              ></a>
            </div>

            <!-- Служба доставки Magnum GO -->
            <div class="contacts__item">
              <h3 class="contacts__item-title">
                {{ $t("deliveryServiceTitle") }}
              </h3>
              <a :href="`tel:${tel}`" class="contacts__phone">
                <span>{{ tel }}</span>
                {{ $t("contactCenterPhone_4") }}
              </a>
              <!-- <a
                href="https://t.me/MagnumGo_bot"
                target="_blank"
                class="telegram-link"
              >
                <img
                  src="@/assets/img/icons/magnum-go-logo.svg"
                  alt="magnum go logo"
                  width="37"
                  height="37"
                />
                <span class="color-pink"> @MagnumGO_bot</span>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getSocialLink } from "@/api/links";
export default {
  name: "AppFooter",
  data() {
    return {
      social: null,
      tel: "7772",
      accordions: [
        {
          id: 1,
          title: "buyers",
          isOpen: false,
          list: [
            {
              router: "catalog",
              label: "discountCatalog",
            },
            {
              router: "stocks",
              label: "stock",
            },
            {
              router: "magnumclub",
              label: "magnumclub",
            },
            {
              router: "magnumgo",
              label: "magnumgo",
            },
            {
              router: "cooking",
              label: "cookingWithMagnum",
            },
            {
              router: "shops",
              label: "shops",
            },
            {
              router: "contacts",
              label: "contacts",
            },
            {
              router: "interview",
              label: "pollByAssortment",
            },
          ],
        },
        {
          id: 2,
          title: "aboutCompany",
          isOpen: false,
          list: [
            {
              router: "about",
              label: "magnumCompany",
            },
            {
              router: "news",
              label: "companyNews",
            },
            {
              router: "career",
              label: "сareerMagnum",
            },
            {
              router: "private-label",
              label: "ourBrands",
            },

            // {
            //   router: "gsp",
            //   label: "GSP",
            // },

            {
              link: "https://corp.magnum.kz/ru",
              label: "magnumCorp",
            },
          ],
        },

        {
          id: 3,
          title: "cooperation",
          isOpen: false,
          list: [
            {
              link: "https://corp.magnum.kz/ru/suppliers?city_id=2",
              label: "suppliersCorp",
            },
            {
              link: "https://corp.magnum.kz/ru/advertisers?city_id=2",
              label: "advertisers",
            },
            {
              link: "https://corp.magnum.kz/ru/tenants?city_id=2",
              label: "forTenants",
            },
            {
              link: "https://corp.magnum.kz/ru/landlords?city_id=2",
              label: "forLandlords",
            },
            {
              link: "https://corp.magnum.kz/ru/bidding?city_id=2",
              label: "tradingInMagnum",
            },
            {
              link: "https://corp.magnum.kz/ru/magnum-opt?city_id=2",
              label: "Magnum opt",
            },
            {
              link: "https://corp.magnum.kz/ru/compliance-service?city_id=2",
              label: "compliancesService",
            },
          ],
        },
      ],
      selectedAccordions: null,
    };
  },
  created() {
    getSocialLink().then((social) => {
      this.social = social;
    });
  },
  mounted() {
    console.log(this.$route.name);
    // let date = new Date().toLocaleString();
    // let firstDate = (date.split(":")[0] + ":" + date.split(":")[1])
    //   .split(",")
    //   .join("");
    // let secondDate = "20.04.2023 00:00";

    // let datetime_regex = /(\d\d)\.(\d\d)\.(\d\d\d\d)\s(\d\d):(\d\d)/;

    // let first_date_arr = datetime_regex.exec(firstDate);
    // let first_datetime = new Date(
    //   first_date_arr[3],
    //   first_date_arr[2],
    //   first_date_arr[1],
    //   first_date_arr[4],
    //   first_date_arr[5]
    // );

    // let second_date_arr = datetime_regex.exec(secondDate);
    // let second_datetime = new Date(
    //   second_date_arr[3],
    //   second_date_arr[2],
    //   second_date_arr[1],
    //   second_date_arr[4],
    //   second_date_arr[5]
    // );
    // console.log(first_datetime.getTime() >= second_datetime.getTime());
    // if (first_datetime.getTime() >= second_datetime.getTime()) {
    //   this.tel = "7777";
    // } else {
    //   this.tel = "7772";
    // }
  },
  methods: {
    toogleAccordions(item) {
      const foundFilter = this.accordions.find((f) => f.id === item.id);
      if (foundFilter) {
        foundFilter.isOpen = !foundFilter.isOpen;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
